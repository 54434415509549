import React, {useEffect, useState} from "react";
import Api from "../../Api/api";
import {toast} from "react-toastify";
import {RiArrowDownSFill, RiArrowUpSFill} from "react-icons/ri";
import {ClipLoader} from "react-spinners";
import UpsellingTable from "./partial/upsellingTable";
import {useStateValue} from "../../states/StateProvider";
import {AES, enc} from "crypto-js";
import {UpsellingHeadersDGG} from "../../staticData/upsellingHeadersDGG";
import Pagination from "../../components/pagination";

const Upselling = () => {

    const searchableFields = [0, 1, 2]
    const dropdownsearchFields = [3, 4, 5, 6, 7, 8, 9]
    const sortableFields = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    const [headers, setHeaders] = useState([])
    const [{
        sortColumnUpselling,
        sortMethodUpselling,
        secretKey,
        portal,
        filterUpselling,
        filterIDUpselling,
        pageUpselling
    }, dispatch] = useStateValue();
    const [superAdmin, setSuperAdmin] = useState('')

    const decryptedBytes = localStorage.getItem('user') ? AES.decrypt(localStorage.getItem('user'), secretKey) : false;
    const user = JSON.parse(decryptedBytes.toString(enc.Utf8))
    const userID = user.ID


    const [rows, setRows] = useState('10');
    const [total, setTotal] = useState(0);
    let PageSize = rows;

    useEffect(() => {
        const delayQuery = setTimeout(async () => {
            setLoading(true)
            setData([])
            if (portal) {
                if (portal === 'dgg') {
                    setHeaders(UpsellingHeadersDGG)
                } else if (portal === 'ruv') {
                    setHeaders(UpsellingHeadersDGG)
                }

                const Data = new FormData()
                Data.append('perPage', rows)
                Data.append('page', pageUpselling)
                Data.append('sort', sortColumnUpselling)
                Data.append('sortMethod', sortMethodUpselling)
                Data.append('filter', JSON.stringify(filterUpselling))
                Data.append('filterID', JSON.stringify(filterIDUpselling))

                try {
                    Api().post(`sp_getDataUpselling/dgg/${userID}`, Data).then(res => {
                        setData(res.data)
                        setTotal(res.data[0]?.total)
                        setLoading(false)
                    }).catch(e => {
                        toast.error('Etwas ist schief gelaufen!!')
                        setLoading(false)
                    })

                } catch (e) {
                    window.location.replace('/anmeldung')
                }
            }
        }, filterUpselling ? 1500 : 0)

        Api().get(`sp_getUpsellingOptions/${'dgg'}`)
            .then(res => {
                setOptions(res.data)
            })

        return () => clearTimeout(delayQuery)

    }, [portal, pageUpselling, sortMethodUpselling, sortColumnUpselling, filterIDUpselling, filterUpselling]);

    useEffect(() => {
        setSuperAdmin(user.isSAdmin)
        if ((user.role === 'ExtDGG' || user.role === 'ManDGG')) {
            dispatch({type: 'SET_PORTAL', item: 'dgg'})
            localStorage.setItem('portal', 'dgg')
        } else if ((user.role === 'ExtRUV' || user.role === 'ManRUV')) {
            dispatch({type: 'SET_PORTAL', item: 'ruv'})
            localStorage.setItem('portal', 'ruv')
        } else {
            if (localStorage.getItem('portal')) {
                dispatch({type: 'SET_PORTAL', item: localStorage.getItem('portal')})
            } else {
                dispatch({type: 'SET_PORTAL', item: 'dgg'})
            }
        }
    }, []);

    function portalSelect(e) {
        dispatch({type: 'SET_PORTAL', item: e.target.value})
        localStorage.setItem('portal', e.target.value)
    }

    function ascSort(id) {
        dispatch({type: "SET_SORT_COLUMN_UPSELLING", item: id})
        dispatch({type: "SET_SORT_UPSELLING_METHOD", item: 'asc'})
    }

    function descSort(id) {
        dispatch({type: "SET_SORT_COLUMN_UPSELLING", item: id})
        dispatch({type: "SET_SORT_UPSELLING_METHOD", item: 'desc'})
    }

    function setPageStates(e) {
        dispatch({type: "SET_PAGE_UPSELLING", item: 1})
        setRows(e.target.value)
    }

    function enableFilter(type, id, val) {

        console.log('type,id, val', type,id, val)
        if (id === 1) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, a: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, a: 1}})
        }
        if (id === 2) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, b: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, b: 2}})
        }
        if (id === 3) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, c: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, c: 3}})
        }
        if (id === 4) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, d: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, d: 4}})
        }
        if (id === 5) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, e: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, e: 5}})
        }
        if (id === 6) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, f: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, f: 6}})
        }
        if (id === 7) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, g: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, g: 7}})
        }
        if (id === 111) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, h: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, h: 111}})
        }
        if (id === 112) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, i: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, i: 112}})
        }
        if (id === 113) {
            dispatch({type: "SET_SORT_UPSELLING_FILTER", item: {...filterUpselling, j: val}})
            dispatch({type: "SET_SORT_UPSELLING_FILTER_ID", item: {...filterIDUpselling, j: 113}})
        }
    }

    const [hasFilter, setHasFilter] = useState(false);

    useEffect(() => {
        const keys = Object.keys(filterUpselling);
        const keysToRestore = keys.slice(-3);
        const valuesToRestore = keysToRestore.map(key => ({[key]: filterUpselling[key]}));
        keysToRestore.forEach(key => delete filterUpselling[key]);
        const isNullUndefEmptyStr = Object.values(filterUpselling).every(value => {
            return value === null || value === undefined || value === '';
        });
        if (isNullUndefEmptyStr) {
            setHasFilter(true)
        } else {
            setHasFilter(false)
        }
        valuesToRestore.forEach(valueObj => {
            const key = Object.keys(valueObj)[0];
            filterUpselling[key] = valueObj[key];
        });
    }, [filterUpselling]);

    function clearFilters() {
        dispatch({
            type: "SET_SORT_UPSELLING_FILTER",
            item: {a: null, b: null, c: null, d: null, e: null, f: null, g: null, h: null, i: null, j: null}
        })
        dispatch({
            type: "SET_SORT_UPSELLING_FILTER_ID",
            item: {a: null, b: null, c: null, d: null, e: null, f: null, g: null, h: null, i: null, j: null}
        })
    }

    return (
        <div className='dashboardContainer'>
            <div className='flex justify-start items-center content-center pb-5'>
                <h2 className='text-2xl lg:text-left'> Upselling</h2>
                {/*{*/}
                {/*    (superAdmin === '1' || role === 'i' || role === 'c') &&*/}
                {/*    <div className='flex justify-start items-center w-fit bg-transparent py-2 px-4 ml-2 rounded-sm'>*/}
                {/*        <p className='w-fit mr-2 text-grey'>Portal: </p>*/}
                {/*        <select*/}
                {/*            disabled={loading}*/}
                {/*            className='col-span-2 text-center text-mainBlue mx-auto pr-1 bg-transparent border border-offWhite rounded-sm lg:w-fit'*/}
                {/*            onChange={portalSelect}*/}
                {/*            value={portal}*/}
                {/*        >*/}
                {/*            <option selected value='dgg'>DGG</option>*/}
                {/*            <option value='ruv'>R+V</option>*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            {/*<div className={`bg-white pt-3 pb-1 px-3 lg:flex sm:block`}>*/}
            {/*</div>*/}
            <div className="flex flex-col p-10 bg-white" style={{height: '75vh'}}>
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden pt-1">
                            {
                                (data?.length === 0 && !loading) &&
                                <div className='centerItemsAbsolute'>
                                    <h2 className='text-2xl text-text font-bold'>
                                        Entschuldigung, keine Daten gefunden
                                    </h2>
                                </div>
                            }
                            <button disabled={hasFilter} onClick={clearFilters}
                                    className={`${hasFilter && 'hideDiv bg-white text-text'} ${loading ? 'hideDiv' : ''} mx-auto mb-5 justify-center w-52 rounded-md border border-offWhite shadow-sm px-4 opacity-80 py-2 bg-cancel text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                            >
                                {!hasFilter ? 'Such-Filter zurücksetzen' : 'Kein Suche Filter'}
                            </button>
                            <div className='overflow-x-hidden table-wrp block'
                                 style={{maxHeight: '60vh'}}>
                                <table className='min-w-full text-left bg-white'>
                                    <thead
                                        className="whitespace-nowrap bg-white border-y border-silver border-x-0 sticky top-0">
                                    <tr>
                                        {
                                            !loading &&
                                            headers?.map(header => (
                                                <th key={header.id} scope="col"
                                                    className={`text-sm text-grey pl-1.5 tooltip`}
                                                >
                                                <span className='flex justify-left'>
                                                    <span
                                                        className={`tooltip mt-1.5 text-center xl:h-fit lg:h-14 ${sortColumnUpselling === header.id && 'text-mainBlue'}`}
                                                    >
                                                            {header.title}
                                                        </span>
                                                        <span
                                                            className={`${!(sortableFields.includes(header.id)) && 'opacity-0'}`}>
                                                            <p className={`cursor-pointer ${sortColumnUpselling === header.id && sortMethodUpselling === 'asc' ? 'text-mainBlue' : ''}`}
                                                               onClick={() => ascSort(header.id)}
                                                            >
                                                                <RiArrowUpSFill size='22px'/>
                                                            </p>
                                                            <p className={`-mt-3.5 cursor-pointer ${sortColumnUpselling === header.id && sortMethodUpselling === 'desc' ? 'text-mainBlue' : ''}`}
                                                               onClick={() => descSort(header.id)}
                                                            >
                                                                <RiArrowDownSFill size='22px'/>
                                                            </p>
                                                        </span>
                                                    </span>
                                                    <span
                                                        className={`${!(searchableFields.includes(header.id)) && 'hideDiv'}`}>
                                                        <input
                                                            className={`h-8 px-2 py-3 search mb-7 ${header.id === 1 ? 'w-96' : 'w-32'}`}
                                                            type='text'
                                                            maxLength="50"
                                                            value={header.id === 1 ? filterUpselling.a : header.id === 2 && filterUpselling.b}
                                                            onChange={(e) => enableFilter('text',header.id, e.target.value)}
                                                            placeholder='Suche...'
                                                        />
                                                    </span>
                                                    <span
                                                        className={`${!(dropdownsearchFields.includes(header.id)) && 'hideDiv'}`}>
                                               <select
                                                   value={header.id === 3 ? filterUpselling.c : header.id === 4 ? filterUpselling.d : header.id === 5 ? filterUpselling.e : header.id === 6 ? filterUpselling.f : header.id === 7 ? filterUpselling.g : header.id === 8 ? filterUpselling.h : filterUpselling.i}
                                                   onChange={(e) => enableFilter('dropdown', header.id, e.target.value)}
                                                   className={`px-4 text-xs py-2 search rounded-md bg-white cursor-pointer mb-7 ${
                                                       (header.id === 3 ? filterUpselling.c : header.id === 4 ? filterUpselling.d : header.id === 5 ? filterUpselling.e : header.id === 6 ? filterUpselling.f : header.id === 7 ? filterUpselling.g : header.id === 8 ? filterUpselling.h : filterUpselling.i) == null || (header.id === 3 ? filterUpselling.c : header.id === 4 ? filterUpselling.d : header.id === 5 ? filterUpselling.e : header.id === 6 ? filterUpselling.f : header.id === 7 ? filterUpselling.g : header.id === 8 ? filterUpselling.h : filterUpselling.i) === ''
                                                       ||
                                                       (header.id === 3 ? filterUpselling.c : header.id === 4 ? filterUpselling.d : header.id === 5 ? filterUpselling.e : header.id === 6 ? filterUpselling.f : header.id === 7 ? filterUpselling.g : header.id === 8 ? filterUpselling.h : filterUpselling.i) == null || (header.id === 3 ? filterUpselling.c : header.id === 4 ? filterUpselling.d : header.id === 5 ? filterUpselling.e : header.id === 6 ? filterUpselling.f : header.id === 7 ? filterUpselling.g : header.id === 8 ? filterUpselling.h : filterUpselling.i) === 'kein Interesse'
                                                           ? 'text-placeholder'
                                                           : 'text-mainBlue'
                                                   }`}
                                               >
                                                  <option className='text-placeholder' value="">Alle</option>
                                                   {options?.map(op => (
                                                       <option className='text-text' key={op.ID} value={op.title}>
                                                           <img src={`${window.location.origin}/icons/${op.icon}`} />
                                                           <span className='ml-2'>{op.title}</span>
                                                       </option>
                                                   ))}
                                                </select>
                                                    </span>
                                                    <br/>
                                                    {
                                                        header.mouseOver?.length > 0 &&
                                                        <p className='tooltiptextInstantOver'>{header.mouseOver}</p>
                                                    }
                                                </th>
                                            ))
                                        }
                                        {/*<th scope="col" className="text-sm w-1/12 text-grey px-2"/>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading &&
                                        <tr className='centerItemsAbsolute'>
                                            <td><ClipLoader size={50} color={'#b4b4b4'}/></td>
                                        </tr>
                                    }
                                    {
                                        data?.map((u) => (
                                            <UpsellingTable
                                                key={u.FP_ID}
                                                FirmaID={u.FP_ID}
                                                portal={portal}
                                                Firma={u.Firma}
                                                Closer={u.Closer}
                                                bav={u.bav}
                                                bkv={u.bkv}
                                                GehaltsExtras={u.GehaltsExtras}
                                                CLS={u.CLS}
                                                HRMT={u.HRMT}
                                                NBG={u.NBG}
                                                bbu={u.bbu}
                                                sortColumn={sortColumnUpselling}
                                                sortMethod={sortMethodUpselling}
                                            />
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`flex justify-end gap-20 ${loading && 'opacity-0'}`}>
                    <div className={`mt-10 ${loading && 'opacity-0'}`}>
                        {
                            data.length > 0 &&
                            <Pagination
                                className="pagination-bar"
                                currentPage={pageUpselling}
                                totalCount={total}
                                pageSize={PageSize}
                                onPageChange={pageNo => dispatch({
                                    type: "SET_PAGE_UPSELLING",
                                    item: pageNo
                                })}
                            />
                        }
                    </div>
                    <p className={`${(data?.length === 0) && 'hideDiv'} mr-2 text-sm text-grey ml-10 mt-10`}>
                        {pageUpselling === 1 ? pageUpselling : (1 + (Number(rows) * pageUpselling)) - Number(rows)} bis {(data?.length < Number(rows)) ? data.length + Number(rows) < total ? data.length + (Number(rows) * pageUpselling) - Number(rows) : total : (Number(rows) + (Number(rows) * pageUpselling)) - Number(rows)} von {total} Einträge
                    </p>
                    <h2 className={`${(data?.length === 0) && 'hideDiv'}  text-sm text-grey mt-10`}>
                        Einträge anzeigen:
                        <span>
                                        <select onChange={setPageStates} className={` bg-transparent text-mainBlue`}>
                                            <option value={'10'}>{10}</option>
                                            <option value={'25'}>{25}</option>
                                            <option value={'10000'}>Alle</option>
                                        </select>
                                    </span>
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default Upselling