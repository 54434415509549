import React, {useEffect, useState} from "react";
import Modal from "../../hooks/modal";
import useModal from "../../hooks/useModal";
import {useStateValue} from "../../states/StateProvider";
import CompanyInfoPopUp from "../../components/modal/companyInfoPopUp";
import CompanyData from "./partial/companyData";
import Api from "../../Api/api";
import {useParams} from "react-router";
import {toast} from "react-toastify";
import {AES, enc} from "crypto-js";
import CompanyInfoPopUpDGG from "../../components/modal/companyInfoPopUpDGG";
import {ClipLoader} from "react-spinners";
import {formatDate} from "../../helper/formatDate";
import UpdateUpselling from "../../components/modal/updateUpselling";
import Status from "../milestones/partial/status";

const ImageComponent = ({src}) => {
    const [imageExists, setImageExists] = useState(true);

    const handleImageError = () => {
        setImageExists(false);
    };

    return (
        <>
            {imageExists && (
                <img
                    src={src}
                    alt="Image"
                    onError={handleImageError} // Set imageExists to false if image fails to load
                />
            )}
        </>
    );
};

const Reporting = () => {
    const [{
        companyInfoModal,
        upsellingModal,
        secretKey,
        upsellingSaved,
        noteSent,
        noteRows,
        remindersSaved
    }, dispatch] = useStateValue();
    const {toggleCompanyInfoModal, toggleUpsellingModal} = useModal();
    const [stepsLoading, setStepsLoading] = useState(true)
    const [info, setInfo] = useState(null)
    const [edit, setEdit] = useState(false)
    const [editInfo, setEditInfo] = useState('')
    const [editInfoFull, setEditInfoFull] = useState('')
    const [editID, setEditID] = useState(1)
    const [data, setData] = useState([])
    const [options, setOptions] = useState([])
    const [infoLoading, setInfoLoading] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const param = useParams()
    const decryptedBytes = localStorage.getItem('user') ? AES.decrypt(localStorage.getItem('user'), secretKey) : false;
    const user = JSON.parse(decryptedBytes.toString(enc.Utf8))

    const [notes, setNotes] = useState([])
    const [notesCount, setNotesCount] = useState(7)
    const [loadingNotes, setLoadingNotes] = useState(false)

    const role = user.role
    useEffect(() => {
        setLoadingNotes(true)
        Api().get(`/getNotes/${param.portal}/${param.id}/${noteRows}`).then(res => {
            console.log('res.data', res.data)
            setNotes(res.data)
            setNotesCount(res.data[0]?.total)
            setLoadingNotes(false)
        }).catch(e => {
            setLoadingNotes(false)
            toast.error('Notizen konnten nicht geladen werden!')
        })
    }, [noteRows, noteSent, remindersSaved]);

    useEffect(() => {
        setStepsLoading(true)
        setInfoLoading(true)
        Api().get(`/customerDetails/${param.portal}/${param.id}`).then(res => {

            setInfo(res.data[0])
        }).catch(e => {
            toast.error('Firmendetails konnten nicht geladen werden!')
        })

        setInfoLoading(false)

        Api().get(`sp_getUpsellingOptions/${'dgg'}`)
            .then(res => {
                setOptions(res.data)
            })

    }, []);

    useEffect(() => {
        setStepsLoading(true)
        setInfoLoading(true)
        Api().get(`/sp_getDataCustUpselling/${param.portal}/${param.id}`).then(res => {
            setCompanyName(res.data.companyName)
            const sortedOptions = res.data.data.sort((a, b) => {
                return a.FP_ID - b.FP_ID;
            });
            setData(sortedOptions[0])
            setStepsLoading(false)
        }).catch(e => {
            toast.error('Firmendetails konnten nicht geladen werden!')
            setStepsLoading(false)
        })
        setInfoLoading(false)

    }, [upsellingSaved]);

    function setEditStates(u, index) {
        if (u[1].split(',')[0] !== 'Vertrag aktiv' && u[1].split(',')[0] !== 'Vertrag inaktiv') {
            setEditInfo(u[1].split(',')[0])
            setEditInfoFull(u)
            setEditID(index + 1)
            setEdit(true)
            toggleUpsellingModal()
        }
    }

    return (
        <div className='dashboardContainer'>
            <CompanyData info={info}
                         portal={param.portal}
                         company={companyName}
                         companyID={param.id}
                         loading={infoLoading}
                         toggle={toggleCompanyInfoModal}
            />
            {
                stepsLoading &&
                <tr className='centerItemsAbsolute'>
                    <td><ClipLoader size={50} color={'#b4b4b4'}/></td>
                </tr>
            }
            {
                !stepsLoading &&
                <div className='lg:flex justify-between my-3 rounded-lg sm:block'>
                    <div className='my-3 rounded-lg sm:block w-6/12 bg-white'>
                        <div className="p-3 text-left font-bold">Bausteine</div>
                        <table className='text-left bg-white'>
                            <thead
                                className="whitespace-nowrap bg-white sticky top-0">
                            <tr>
                                <th scope="col" className="text-sm text-grey pl-1.5 tooltip">

                                </th>
                                <th scope="col" className="text-sm text-grey pl-1.5 tooltip">

                                </th>
                                <th scope="col" className="text-sm text-grey pl-1.5 tooltip">

                                </th>
                            </tr>
                            </thead>
                            <tbody className='gap-4'>
                            {data && Object.entries(data)?.slice(1)?.map((u, index) => {
                                const imageSrc = `${window.location.origin}/icons/${u[1].split(',')[0].replace(/\s/g, "")}.png`;
                                const displayText = u[1].split(',')[0];
                                const formattedDate = formatDate(u[1].split(',')[1]?.split(':')[1]);

                                return (
                                    <tr key={u[0]} className="text-sm">
                                        <td>{u[0]}</td>
                                        <td className='flex gap-4 border border-offWhite p-2 mt-2'>
                                            {displayText !== 'Keine Information' && <ImageComponent src={imageSrc}/>}

                                            <div className='flex justify-between w-60'>
                                                <span>{displayText}</span>
                                                <span>{formattedDate}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                className={`${displayText === 'Vertrag aktiv' || displayText === 'Vertrag inaktiv' ? 'bg-lightBlue cursor-no-drop' : ''} rounded-lg bg-mainBlue py-2 px-4 text-offWhite text-center`}
                                                onClick={() => setEditStates(u, index)}
                                            >
                                                bearbeiten
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>

                    <div className='bg-white mt-4 px-3 pb-10 w-6/12 ml-3 rounded-lg min-h-full'>
                        <Status company={companyName}
                                companyID={param.id}
                                portal={param.portal}
                                notes={notes}
                                role={role}
                                loadingNotes={loadingNotes}
                                count={notesCount}
                                showReminderSection={false}
                        />
                    </div>

                </div>

            }

            <Modal toggle={toggleUpsellingModal}
                   visible={upsellingModal}
                   small
                   component={<UpdateUpselling FPID={param.id} editID={editID} options={options} dataFull={editInfoFull}
                                               data={editInfo}
                                               toggle={toggleUpsellingModal}/>}
            />

            <Modal toggle={toggleCompanyInfoModal}
                   visible={companyInfoModal}
                   component={param.portal === 'dgg' ?
                       <CompanyInfoPopUpDGG portal={param.portal} Info={info} company={param.id}/>
                       :
                       <CompanyInfoPopUp portal={param.portal} Info={info} company={param.id}/>
                   }
            />
        </div>
    )
}

export default Reporting